<template>
  <div>
    <div class="header-information">
      <div class="box-information-page">
        <h3>Usuários cadastrados</h3>
        <div class="indicator"></div>
      </div>
    </div>
    <v-card class="rounded-lg card-table" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span> Criar Usuário </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="usuarios"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="totalPassengers"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud
            :resource="item"
            :profile="profile"
            v-on:eventname="deletarUsuario"
          />
        </template>

        <template v-slot:[`item.cpf`]="{ item }">
          {{ item.cpf | VMask("###.###.###-##") }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="header-information">
            <div class="box-information-page">
              <h3>Criar novo usuário</h3>
              <div class="indicator"></div>
            </div>
          </div>

          <v-form @submit.prevent="create" ref="form">
            <v-text-field
              class="rounded-lg"
              label="Nome"
              placeholder="Nome completo"
              outlined
              v-model="name"
              :rules="formRules"
              dense
            ></v-text-field>
            <v-text-field
              class="rounded-lg"
              label="E-mail"
              placeholder="E-mail"
              outlined
              v-model="email"
              :rules="formRules"
              dense
            ></v-text-field>
            <v-text-field
              class="rounded-lg"
              label="CPF"
              placeholder="CPF"
              outlined
              v-model="cpf"
              :rules="formRules"
              dense
              v-mask="'###.###.###-##'"
            ></v-text-field>
            <v-select
              class="mr-2"
              v-model="selectedsuperintendencia"
              :items="superintendencia"
              item-text="name"
              item-value="id"
              outlined
              :rules="formRules"
              dense
              label="Superintendência"
            ></v-select>
            <v-select
              class="mr-2"
              v-model="selectedProfile"
              :items="profile"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Perfil"
            ></v-select>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg"
                color="#000"
                text
                elevation="0"
                @click="dialog = false"
              >
                <span>Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                elevation="0"
                color="#27ae60"
                type="submit"
                text
                :loading="loadingBTN"
              >
                <span class="textBtn">Criar usuário</span>
                <v-icon color="#27ae60" small class="ml-2"
                  >mdi-arrow-right</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudUsers-comp.vue";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      formRules: [(v) => !!v || "Campo obrigatório."],

      page: 1,
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},

      usuarios: [],
      superintendencia: [],
      profile: [],
      message: "",
      snackbar: false,
      loadingBTN: false,

      name: "",
      cpf: "",
      email: "",
      selectedsuperintendencia: "",
      selectedProfile: "",
    };
  },

  components: {
    Crud,
  },

  created() {
    axios.get("profile").then((response) => {
      this.profile = response.data.data;
    });
    axios // BUSCA SUPERINTENDENCIAS
      .get(`/superintendencies`)
      .then((response) => {
        this.superintendencia = response.data.data;
      });
  },

  watch: {
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    indoDataTable() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      console.log("Page Number ", page, itemsPerPage);
      let pageNumber = page;

      axios.get(`/users?page=${pageNumber}`).then((response) => {
        this.loading = false;
        this.usuarios = response.data.data;
        this.totalPassengers = response.data.total;
        this.numberOfPages = response.data.last_page;
      });
    },

    mounted() {
      this.indoDataTable();
    },

    create() {
      if (this.$refs.form.validate()) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        this.loadingBTN = true;

        const dados = new Object();
        dados.name = this.name;
        dados.cpf = newCPF;
        dados.email = this.email;
        dados.superintendence_id = this.selectedsuperintendencia;
        dados.profile_id = this.selectedProfile;
        axios
          .post(`/users`, dados)
          .then((response) => {
            this.indoDataTable();
            this.message = response.data.message;
            this.dialog = false;
            this.snackbar = true;
            this.loadingBTN = false;
          })
          .catch((error) => {
            //SOLUÇÃO TEMPORÁRIA, erro no envio de email.
            if (error.includes("Connection could not")) {
              this.indoDataTable();
              this.message = "Registro inserido com sucesso";
              this.dialog = false;
              this.snackbar = true;
              this.loadingBTN = false;
            }
          });
      }
    },

    deletarUsuario(resource) {
      axios.delete(`/users/${resource}`).then((response) => {
        this.dialogAPagar = false;
        this.message = response.data.message;
        this.snackbar = true;
      });

      let usuarios = this.usuarios;
      let newArray = usuarios.filter(function (usuarios) {
        return usuarios.id != resource;
      });
      this.usuarios = newArray;
    },
  },
};
</script>

<style scoped>
.header-information {
  margin-bottom: 20px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.card-table {
  padding: 30px !important;
}

.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #27ae60;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
