<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="dialogEdit">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" small @click="dialogAPagar = true">
            mdi-delete
          </v-icon>
        </span>
      </template>
      <span>Apagar</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="header-information">
          <div class="box-information-page">
            <h3>
              Editar usuário:
              <small class="username-edit">{{ resource.name }}</small>
            </h3>
            <div class="indicator"></div>
          </div>
        </div>

        <v-form @submit.prevent="edit" ref="form">
          <v-text-field
            label="Nome"
            placeholder="Placeholder"
            outlined
            v-model="resource.name"
            dense
            @keyup="uppercase"
            :rules="nameRules"
            requerid
          ></v-text-field>
          <v-text-field
            label="E-mail"
            placeholder="Placeholder"
            outlined
            v-model="resource.email"
            dense
            :rules="emailRules"
            requerid
          ></v-text-field>
          <v-text-field
            label="CPF"
            placeholder="Placeholder"
            outlined
            v-model="resource.cpf"
            v-mask="'###.###.###-##'"
            dense
            :rules="cpfRules"
            requerid
          ></v-text-field>
          <v-select
            class="mr-2"
            v-model="superintendence_id"
            :items="superintendencia"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Superintendência"
            :rules="superintendenciaRules"
            requerid
          ></v-select>

          <v-select
            class="mr-2"
            v-model="resource.profile"
            :items="profile"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Perfil"
          ></v-select>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="#000"
              text
              elevation="0"
              @click="dialogEditar = false"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
              text
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            usuários cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter mt-3">
          <v-btn
            class="rounded-lg btn"
            color="#000"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            text
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#27ae60" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  props: {
    resource: {
      type: Object,
    },
    profile: {
      type: Object,
    },
  },

  data() {
    return {
      selectedProfile: "",
      dialogEditar: false,
      dialogAPagar: false,
      superintendencia: [],
      superintendence_id: parseInt(this.resource.superintendence_id),
      message: "",
      snackbar: false,
      nameRules: [
        (v) => !!v || "O nome é obrigatório",
        (v) => (v && v.length >= 5) || "Preencha com o nome e sobrenome.",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],
      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],
      emailRules: [
        (v) => !!v || "O e-mail é obrigatório",
        (v) => /.+@.+/.test(v) || "Insira um e-mail válido",
      ],
      superintendenciaRules: [(v) => !!v || "O nome é obrigatório"],
    };
  },
  methods: {
    dialogEdit() {
      this.dialogEditar = true;

      axios.get(`/superintendencies`).then((response) => {
        this.superintendencia = response.data.data;
      });
    },

    uppercase() {
      this.resource.name = this.resource.name.toUpperCase();
    },

    edit() {
      console.log(this.resource)
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.id = this.resource.id;
        dados.name = this.resource.name.toUpperCase();
        dados.cpf = this.resource.cpf;
        dados.profile_id = this.resource.profile;
        dados.email = this.resource.email;
        dados.superintendence_id = this.superintendence_id;

        axios.put(`/users/${dados.id}`, dados).then((response) => {
          this.dialogEditar = false;
          this.message = response.data.message;
          this.snackbar = true;
        });
      }
    },

    deletar() {
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.header-information {
  margin-bottom: 30px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #27ae60;
}

.username-edit {
  font-family: "Poppins";
  font-weight: 500;
  color: #1344a0;
}
</style>
