<template>
  <div>
    <Table />
  </div>
</template>

<script>
import Table from '../components/usuarios/Table-comp.vue';
export default {
components: {
    Table
},
  data: () => ({
  }),
}
</script>

<style scoped>
  .slide-enter, .slide-leave-to {
    transform: translate(-50px);
    opacity: 0;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all 0.3s;
  }
</style>